export enum SegmentEvents {
  CTAClicked = 'CTAClicked',
  ExternalLinkClicked = 'ExternalLinkClicked',
  SignUpSuccessful = 'SignUpSuccessful',
  LoginSuccessful = 'LoginSuccessful',
  ManufacturerLearnMoreClicked = 'ManufacturerLearnMoreClicked',
  SearchViewed = 'SearchViewed',
  ListingClicked = 'ListingClicked',
  ListingViewed = 'ListingViewed',
  FinancingClicked = 'FinancingClicked',
  SendMessageClicked = 'SendMessageClicked',
  RevealPhoneNumberClicked = 'RevealPhoneNumberClicked',
  SendMessageSuccessful = 'SendMessageSuccessful',
  RentRvClicked = 'RentRvClicked',
  DealerMessageClicked = 'DealerMessageClicked',
  DealerMessageSuccessful = 'DealerMessageSuccessful',
  ListingSuccessful = 'ListingSuccessful'
}
